<template>
    <modal-dialog
        id="prompt"
        :show.sync="shown"
        :dismissable="dismissable"
        class="modal-prompt"
    >
        <template v-slot:title>
            <span
                class="h5"
            >
                {{ title }}
            </span>
        </template>

        <p>{{ message }}</p>
        <form-input
            id="modal-prompt-value"
            name="value"
            :auto-select="true"
            v-model="prompt_value"
            :required="true"
            :live="true"
        />

        <template v-slot:footer>
            <default-button
                class="mr-2"
                @click.prevent="dismiss(false)"
            >
                {{ cancel }}
            </default-button>
            <default-button
                color="primary"
                :disabled="!prompt_value"
                class="ml-2"
                @click.prevent="dismiss(true)"
            >
                {{ ok }}
            </default-button>
        </template>
    </modal-dialog>
</template>

<script type="text/javascript">
import ModalDialog from "./ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "ModalPrompt",
    components: {
        ModalDialog,
        FormInput,
        DefaultButton
    },
    mounted () {
        this.reset()
    },
    watch: {
        value: "reset"
    },
    methods: {
        reset () {
            this.prompt_value = this.value
        },
        dismiss (save) {
            if(save) this.$emit("save", this.prompt_value)
            else this.$emit("cancel")
        }
    },
    computed: {
        shown: {
            get: function () {
                return true
            },
            set: function (show) {
                if(!show) this.dismiss(false)
            }
        }
    },
    props: {
        title: {
            type: String,
            default: "",
            required: true
        },
        message: {
            type: String,
            default: "",
            required: true
        },
        value: {
            type: String,
            default: ""
        },
        dismissable: {
            type: Boolean,
            default: false
        },
        cancel: {
            type: String,
            default: "Cancel"
        },
        ok: {
            type: String,
            default: "OK"
        }
    },
    data () {
        return {
            prompt_value: ""
        }
    }
}
</script>
